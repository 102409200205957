/* =============== General ============= */
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.background {
  background: url("./assets/images/4.png");
  background-size: contain;
}

.section-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 55px;
  border-bottom: 4px solid #cc0001;
}

.section-subtitle {
  font-weight: 400;
  font-size: 60px;
  line-height: 80px;
  color: #fff;
  -webkit-text-stroke: 0.5px black;
}

.pageTitle {
  background: rgba(80, 80, 80, 0.54);
  width: fit-content;
  clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
}

.swiper-btn {
  background: transparent;
  outline: none;
  border: none;
}

a
{
  text-decoration: none;
  color: rgb(89, 88, 88);
  font-weight: bold;
}

a:hover
{
  color: rgb(46, 46, 46);
}

/* ================ Navbar ============== */
.nav1 {
  background: transparent;
  z-index: 10;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.nav-item {
  color: #fff;
  font-style: normal;
  font-weight: 501;
}

.nav-item:not(:last-child) {
  border-right: 1px solid #fff;
  padding: 0 2em !important
}

.nav-item:last-child {
  margin-left: 2em !important;
  background-color: #cc0001;
}

.nav-item:hover:not(:last-child)::after {
  content: "";
  position: absolute;
  margin: auto;
  left: 30%;
  top: 30px;
  bottom: 0;
  width: 40%;
  border-bottom: 3px solid #dd0a17;
}

@media (max-width: 991px) {
  .nav1 {
    background: rgba(0, 0, 0, 0.3);
  }

  .nav-item {
    color: #cc0001;
    margin-bottom: 5px;
    border-right: 1px solid #fff;
    font-weight: 501;
  }

  .nav-item:first-child {
    margin-top: 20px;
  }
}

/* ----------- Default Nav --------- */
.nav-default {
  background-color: #7c0001;
  font-weight: 400;
  clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 0% 100%);
  width: 95%;
}

.navShape {
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-color: #cc0001;
}

@media (max-width: 991px) {
  .nav-default {
    width: 90%;
  }
  .nav-link {
    margin-top: 4px;
  }
  .nav-link:first-child {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .nav-logo {
    left: 38px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 700px) {
  .nav-logo {
    left: 43px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 768px) {
  .nav-logo {
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-logo {
    left: 40px;
  }
}

@media only screen and (min-width: 991px) {
  .nav-logo {
    left: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .nav-logo {
    left: 58px;
  }
}

/* ================ Header ============== */
.header {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-caption {
  z-index: 2;
  width: 40%;
  position: relative;
}

.header-caption h2 {
  font-size: 2.8em;
}

.header-caption:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 80%;
  border-bottom: 6px solid #fff;
}

/* ================ Counter ============== */
.counter {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 500px;
}

.counter-content {
  z-index: 1;
}

@media (max-width: 991px) {
  .counter-content {
    z-index: 1;
  }
}

.counter-shape1 {
  top: 28px;
}

.counter-shape2 {
  bottom: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 840px) {
  .counter-shape1 {
    top: 56px;
  }

  .counter-shape2 {
    bottom: 56px;
  }
}

@media only screen and (min-width: 840px) and (max-width: 900px) {
  .counter-shape1 {
    top: 52px;
  }

  .counter-shape2 {
    bottom: 52px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 990px) {
  .counter-shape1 {
    top: 49px;
  }

  .counter-shape2 {
    bottom: 49px;
  }
}

@media only screen and (min-width: 990px) and (max-width: 1024px) {
  .counter-shape1 {
    top: 47px;
  }

  .counter-shape2 {
    bottom: 47px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1050px) {
  .counter-shape1 {
    top: 45px;
  }

  .counter-shape2 {
    bottom: 45px;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1156px) {
  .counter-shape1 {
    top: 40px;
  }

  .counter-shape2 {
    bottom: 40px;
  }
}

@media only screen and (min-width: 1156px) and (max-width: 1222px) {
  .counter-shape1 {
    top: 36px;
  }

  .counter-shape2 {
    bottom: 36px;
  }
}

@media only screen and (min-width: 1222px) and (max-width: 1287px) {
  .counter-shape1 {
    top: 33px;
  }

  .counter-shape2 {
    bottom: 33px;
  }
}

@media only screen and (min-width: 1500px)  {
  .counter-shape1 {
    top: 18px;
  }

  .counter-shape2 {
    bottom: 18px;
  }
}


/* ================ About ============== */
.qualitative {
  background: url("assets/images/kiss2.jpg") top right no-repeat;
}


/* ================ Projects ============== */
.project-img {
  width: 50%;
}

.width100
{
  width: 100%;
}

/* ================ Clients =============== */
.clients img {
  filter: grayscale(100%);
}

.clients img:hover {
  filter: grayscale(0);
  cursor: pointer;
}

/* ================ Services =============== */
.serviceName {
  background: rgba(80, 80, 80, 0.54);
  clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
  width: 60%;
}

.serviceName:hover {
  transition: all 0.4s ease-in-out;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .serviceName {
    width: 85%;
  }
}

/* ============== Footer ============ */
.footerSection {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer {
  background: rgba(82, 82, 82, 0.7);
  bottom: 0;
  padding: 15px 0;
}

@media (max-width: 768px) {
  .footer{
    padding: 0;
  }
}

/* ============== Map ============ */

.map-responsive {
  overflow: hidden;

  padding-bottom: 56.25%;

  position: relative;

  height: 0;
}

.map-responsive iframe {
  left: 0;

  top: 0;

  height: 100%;

  width: 100%;

  position: absolute;
}
